import React, {FC} from 'react';
import cls from "./custom_fields.module.scss"

interface IProps {
    className: string,
    id: string,
    type: string,
    placeholder: string,
    autoComplete: string,
    value: string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    name: string,
    disabled?: boolean,
    required?: boolean,
    err?: boolean,
}

const CustomTextField: FC<IProps> = (props) => {

    const {
        className = "",
        id = "",
        type = "",
        placeholder = "",
        autoComplete = "",
        value,
        onChange,
        name = "",
        disabled = false,
        required = false,
        err = false
    } = props

    const inputClasses = {
        true: `${cls.custom_input_field} ${cls.err_field}`,
        false: cls.custom_input_field
    }

    const isRequire = {
        true: <span className={cls["red-fms"]}> *</span>,
        false: null
    }

    return (
        <div className={`${cls.custom_input_checkout} ${className}`}>
            <input
                className={inputClasses[`${err}`]}
                id={id}
                type={type}
                name={name}
                placeholder=" "
                autoComplete={autoComplete}
                value={value}
                onChange={onChange}
                disabled={disabled}
                required={required}
            />
            <label className={cls.custom_input_label} htmlFor={id}>
                {placeholder}
                {isRequire[`${required}`]}
            </label>
        </div>
    )
}

export default CustomTextField