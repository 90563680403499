import {IProduct} from "../../../types/homePageTypes";
import {ICurrencies} from "../../ForCWV/Wrappers/MainLayout";

export const addToCartEventPixel = (product: IProduct, quantity: number, selectedRate: ICurrencies) => {
    if (window.fbq) {
        window.fbq('track', 'AddToCart', {
            product_name: product.flats[0].name,
            product_sku: product.sku,
            product_id: product.flats[0].id,
            product_type: product.type,
            product_price: product.flats[0].price,
            product_quantity: quantity,
            currency: selectedRate.code
        });
    }
};