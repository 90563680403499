import {useRouter} from "next/router";
import cls from "./customFooter.module.scss"
import {FailSvg} from "../../../../svg";
import React, {FC, ReactNode, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IState} from "../../../../types/state";
import {useToast} from "../../../../atoms/Toast/useToast";
import {setPopup, setPopupName} from "../../../../store/general";
import {mobileSearchOpen} from "../../../../store/mobile-menu";
import CustomIndicator from "../CustomHeader/CustomIndicators/CustomIndicator";
import {TranslationsType} from "../../../../types/homePageTypes";

const urlSender = (url: string): Record<string, string> => {
    return {
        "true": url,
        "false": ""
    }
}

interface IProps {
    translationsData: TranslationsType;
    activeMobileNavElement: number
}

const CustomBottomNavigation: FC<IProps> = (
    {
        translationsData,
        activeMobileNavElement = 0
    }
) => {
    const {push: routerPush} = useRouter();
    const toast = useToast();
    const dispatch = useDispatch();
    const cart = useSelector((state: IState) => state.cart);
    const wishlist = useSelector((state: IState) => state.wishlist);
    const loggedIn = useSelector((state: IState) => state.customer.authenticated);
    const mobileMenuState = useSelector((state: IState) => state.mobileMenu);
    const navRef = useRef<any>(null)
    let scrollTimeout: any
    const homeIndicatorProps = {
        url: "/",
        className: "",
        dropdown: "",
        value: "",
        iconWrapperClassName: "",
        title: "",
    }
    const cartIndicatorProps = {
        url: "/cart",
        value: cart?.items.length,
        iconWrapperClassName: "",
        title: "",
        dropdown: "",
    }
    const favouriteIndicatorProps = {
        iconWrapperClassName: "",
        dropdown: "",
        title: "",
        className: cls["indicator--mobile"],
        url: urlSender("/wishlist")[`${loggedIn}`],
        value: wishlist.length
    }
    const signInIndicatorProps = {
        iconWrapperClassName: "user_stroke",
        className: `${cls["indicator--mobile"]} ${cls.user_indicator}`,
        url: urlSender("/account/profile")[`${loggedIn}`],
        dropdown: "",
        title: "",
        value: "",
    }
    const searchIndicatorProps = {
        iconWrapperClassName: "mobile_search_wrapper",
        className: ``,
        url: "",
        icon: "searchIcon",
        dropdown: "",
        title: "",
        value: "",
    }
    const openToastForGuest = {
        "true": () => {
            toast.open(
                <span className="d-flex faild-toast-fms">
                    <FailSvg className={cls["click-disabled"]}/>
                    {translationsData.ToastSignIn}
                </span>
            )
        },
        "false": () => routerPush("/wishlist")
    }
    const isThisElementActive = (activeIcon: ReactNode, icon: ReactNode) => {
        return {
            "true": {lineClass: cls["under_line__active"], divClass: cls["local-item-active"], icon: activeIcon},
            "false": {lineClass: "", divClass: "", icon}
        }
    }

    const authenticated = useSelector((state: IState) => state.customer.authenticated);

    const handleAuthorizedClass = {
        true: "userIcon_active",
        false: "userIcon"
    }

    const bottomNavItems = [
        /*** Home ***/
        {
            icon: <CustomIndicator icon="homeIcon" {...homeIndicatorProps}/>,
            activeIcon: <CustomIndicator icon="homeIcon" {...homeIndicatorProps}/>,
            param: ['/'],
            onClick: () => routerPush("/")
        },
        /*** Cart ***/
        {
            icon: <CustomIndicator className={cls["indicator--mobile"]} icon="cartIcon" {...cartIndicatorProps}/>,
            activeIcon: <CustomIndicator icon="cartIcon" className="" {...cartIndicatorProps}/>,
            param: ['/cart', '/checkout'],
            onClick: () => routerPush("/cart")
        },
        /*** Divider ***/
        {
            param: [], onClick: () => {
            }
        },
        /*** Favourite ***/
        {
            icon: <CustomIndicator icon="heartIcon" {...favouriteIndicatorProps} />,
            activeIcon: <CustomIndicator icon="heartIcon" {...favouriteIndicatorProps} />,
            param: ['/wishlist'],
            onClick: () => {
                openToastForGuest[`${!loggedIn}`]()
            }
        },
        /*** Sign in ***/
        {
            icon: <CustomIndicator {...signInIndicatorProps} icon={handleAuthorizedClass[`${authenticated}`]}/>,
            activeIcon: <CustomIndicator {...signInIndicatorProps} icon="userIcon_active"/>,
            param: ['/account/profile', '/account/addresses', '/account/addresses/new', '/account/orders', '/signin'],
            onClick: () => {
                const openModalForGuest = {
                    "true": () => {
                        (() => {
                            dispatch(setPopup(true));
                            dispatch(setPopupName("register"))
                        })()
                    },
                    "false": () => routerPush("/account/profile")
                }
                openModalForGuest[`${!loggedIn}`]()
            }
        },
    ];

    useEffect(() => {
        if (!mobileMenuState.open) {
            let prevPos = window.pageYOffset;

            const scrollFunc = () => {
                if (navRef.current) {
                    if (window.pageYOffset > prevPos) {
                        navRef.current.style.transform = 'translate(0, 100px)';
                    } else {
                        navRef.current.style.transform = 'translate(0, 0)';
                    }
                    prevPos = window.pageYOffset;
                }

                clearTimeout(scrollTimeout);

                scrollTimeout = setTimeout(() => {
                    if (navRef.current) {
                        navRef.current.style.transform = 'translate(0, 0)';
                    }
                }, 300);
            };

            window.addEventListener('scroll', scrollFunc);

            return () => {
                window.removeEventListener('scroll', scrollFunc);
                clearTimeout(scrollTimeout);
            };
        }
    }, [mobileMenuState.open]);

    return (
        <div className={cls.bottom_footer} ref={navRef}>
            <div
                className={cls.bottom_search}
                onClick={(e) => {
                    e.stopPropagation()
                    dispatch(mobileSearchOpen())
                }}>
                <CustomIndicator {...searchIndicatorProps} />
            </div>
            <div className={cls["bottomNavigation-wrapper"]}>
                {bottomNavItems.map(({param, onClick, icon, activeIcon}, index) => {
                    return (
                        <div
                            key={index}
                            className={`${cls["bottom-nav-item"]} ${isThisElementActive(activeIcon, icon)[`${index === activeMobileNavElement}`].divClass}`}
                            id={`nav-item-${param}`}
                            onClick={() => {
                                onClick()
                            }}
                        >
                            <div className={` ${cls["indicator"]} `} style={{pointerEvents: "none"}}>
                                <span className={cls.indicator__area}>
                                    {isThisElementActive(activeIcon, icon)[`${index === activeMobileNavElement}`].icon}
                                </span>
                            </div>
                            <div
                                className={isThisElementActive(activeIcon, icon)[`${index === activeMobileNavElement}`].lineClass}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default CustomBottomNavigation
