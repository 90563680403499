import React, {FC} from "react";
import CookieConsent from "react-cookie-consent";
import {CookieSVG} from "../svg";
import {TranslationsType} from "../types/homePageTypes";

interface IProps {
    translationsData: TranslationsType;
    gdprTitle: string;
    gdprContent: string;
}

const AcceptCookies: FC<IProps> = ({
                                       translationsData,
                                       gdprTitle,
                                       gdprContent
                                   }) => {
    const ariaAcceptLabel = translationsData.AcceptCookie

    return (
        <CookieConsent
            location="bottom"
            buttonText={translationsData.AcceptCookie}
            visible="byCookieValue"
            buttonClasses="cookie_button"
            buttonWrapperClasses="cookie_button_wrapper"
            expires={30}
            containerClasses="cookie_body"
            contentClasses="cookie_content"
            ariaAcceptLabel={ariaAcceptLabel}
        >
            <div className="cookie_body_elements">
                <div className="cookie_image">
                    <CookieSVG/>
                </div>
                <div>
                    <h3>{gdprTitle}</h3>
                    <p>{gdprContent}</p>
                </div>
            </div>
        </CookieConsent>
    );
}

export default AcceptCookies
