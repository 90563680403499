import React, {FC, ReactNode} from "react"
import cls from "./customHeader.module.scss"
import {ArrowRoundedRight6x9Svg} from "../../../../svg";
import Link from "next/link";
import { IMenu } from "../../../../types/productPageTypes";

interface IProps {
    items: Array<IMenu>
}

const CustomItemList: FC<IProps> = ({ items }) => {
    const hasChildren: (item: { children: any[] }) => Record<string, Record<string, ReactNode | null>> = (item) => {
        return {
            "true": {
                "arrow": <ArrowRoundedRight6x9Svg className={cls.menu__arrow}/>,
                "submenu": <CustomItemList items={item.children}/>
            },
            "false": {
                "arrow": null,
                "submenu": null
            }
        }
    }
    return <ul className={cls.menu__submenu}>
        {
            items.map(({
                           name = "",
                           children = [],
                           url_key = "",
                           target
                       }, index: number) => (
                <li className={cls.menu__li} key={index}>
                    <Link href={url_key} prefetch={true}>
                        <a className={cls.menu__li__a} {...target}>
                            <span className={cls.submenu__item_name}>{name}</span>
                            {hasChildren({children})[`${!!children?.length}`].arrow}
                        </a>
                    </Link>
                    {hasChildren({children})[`${!!children?.length}`].submenu}
                </li>
            ))
        }
    </ul>
};

export default CustomItemList