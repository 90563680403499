import React, {FC, ReactNode, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Link from "next/link";
import {BlogSVG, CategorySVG, ContactusSVG, PagesSVG, TrackingSVG} from "../../../../../svg";
import {IState} from "../../../../../types/state";
import {mobileMenuClose} from "../../../../../store/mobile-menu";
import cls from "./customMobileMenu.module.scss"
import CustomMobileLinks from "./CustomMobileLinks";
import CustomCurrDropDown from "../../CustomCurrDropDown";
import CustomMobileMenuFooter from "./CustomMobileMenuFooter";
import {ICustomMobileMenu} from "../../MainLayout";

interface ILinkItems {
    linkHref: string,
    key_INTL: string,
    svg: ReactNode
}

const LinksItems: FC<ILinkItems> = ({
                                        linkHref,
                                        key_INTL,
                                        svg
                                    }) => {
    const dispatch = useDispatch();
    return (
        <ul className={cls["mob-links__item"]}>
            <Link href={linkHref}>
                <a onClick={() => dispatch(mobileMenuClose())}>
                    <span className={cls["mobile-links__blok"]}>{svg}{key_INTL}</span>
                </a>
            </Link>
        </ul>
    )
}

const CustomMobileMenu: FC<ICustomMobileMenu> = ({
                                                     menus,
                                                     categories,
                                                     blogValue,
                                                     salesActiveSettings: {
                                                         Tracking = "",
                                                         ContactWithUs = ""
                                                     } = {},
                                                     translationsData,
                                                     selectedRate,
                                                     currencies,
                                                     phoneValue,
                                                     footerAddressValue,
                                                     footerEmailValue,
                                                     socialsFooter,
                                                     dbName
                                                 }) => {

    useEffect(() => {
        const handleEvent = () => {
            dispatch(mobileMenuClose())
        }

        window.addEventListener("beforeunload", handleEvent)

        return () => window.removeEventListener('beforeunload', handleEvent)
    }, []);

    const dispatch = useDispatch();
    const mobileMenuOpen = useSelector((state: IState) => state.mobileMenu.open);
    const classNamesHandler = {
        "true": cls["mobilemenu--open"],
        "false": ""
    }
    const hasElement: (attributes: { linkHref: string; key_INTL: string; svg: ReactNode })
        => Record<string, ReactNode> = (attributes) => {
        return {
            "true": <LinksItems {...attributes} />,
            "false": null
        }
    }

    const handleCurrencyDropdown = {
        true: <CustomCurrDropDown selectedSymbol={selectedRate?.symbol} selectedCurr={selectedRate?.code}
                                  currencies={currencies}/>,
        false: null
    }

    return <div className={`${cls["mobilemenu"]} ${classNamesHandler[`${mobileMenuOpen}`]}`}>
        <div className={cls.mobilemenu__backdrop} onClick={() => dispatch(mobileMenuClose())}/>
        <div className={cls.mobilemenu__body}>
            <div className={cls.mobilemenu__content}>
                <CustomMobileLinks
                    links={[{label: "Cat", children: categories}]}
                    onItemClick={() => dispatch(mobileMenuClose())}
                    labelRender={categories[0].name}
                    svgRender={<CategorySVG/>}
                />
                <CustomMobileLinks
                    links={[{label: "Cat", children: menus}]}
                    onItemClick={() => dispatch(mobileMenuClose())}
                    labelRender={translationsData?.Pages}
                    svgRender={<PagesSVG/>}
                />
                {
                    hasElement({
                        linkHref: "/posts",
                        key_INTL: translationsData?.Posts,
                        svg: <BlogSVG/>
                    })[`${!!+blogValue}`]
                }
                {
                    hasElement({
                        linkHref: "/contactwithus",
                        key_INTL: translationsData?.ContactWithUs,
                        svg: <ContactusSVG/>
                    })[`${!!(+ContactWithUs)}`]
                }
                {
                    hasElement({
                        linkHref: "/tracking",
                        key_INTL: translationsData?.Tracking,
                        svg: <TrackingSVG/>
                    })[`${!!(+Tracking)}`]
                }
                {handleCurrencyDropdown[`${currencies.length > 1}`]}
            </div>
            <CustomMobileMenuFooter
                phoneValue={phoneValue}
                footerAddressValue={footerAddressValue}
                footerEmailValue={footerEmailValue}
                socialsFooter={socialsFooter}
                dbName={dbName}
                translationsData={translationsData}
            />
        </div>
    </div>
}


export default CustomMobileMenu
