import React, {FC, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Link from "next/link";
import {useToast} from "../../../../../atoms/Toast/useToast";
import cls from "../customHeader.module.scss"
import CustomIndicator from "./CustomIndicator";
import {ICartItemType, IState} from "../../../../../types/state";
import {TranslationsType} from "../../../../../types/homePageTypes";
import Image from "next/image";
import {domainUrl} from "../../../../../helper";
import {Cross10Svg} from "../../../../../svg";
import {cartRemoveItem} from "../../../../../store/cart";
import CustomCartDropDown from "./CustomCartDropDown";
import {ICurrencies} from "../../MainLayout";
import NotAvailable from "../NotAvailable";
import {useRouter} from "next/router";

interface ICustomIndicatorCart {
    translationsData: TranslationsType,
    allowCheckoutValue: string,
    dbName: string;
    locale: string;
    selectedRate: ICurrencies
}

const CustomIndicatorCart: FC<ICustomIndicatorCart> = (
    {
        translationsData,
        allowCheckoutValue,
        dbName,
        selectedRate,
        locale
    }
) => {
    const {open: toastOpen} = useToast();
    const { push: routerPush } = useRouter()
    const dispatch = useDispatch();
    const customerToken = useSelector((state: IState) => state.customer.token);
    const {loading = null, ...cartData} = useSelector((state: IState) => state.cart) || {};
    const cartToken = useSelector((state: IState) => state.cartToken)
    const [dropDown, setDropDown] = useState<React.ReactElement>(<></>)

    const isCheckAllow = useCallback(() => {
        const openToastHandler = {
            "true": {
                "true": () => {
                    routerPush("/signin?fm=guest").then(r => r)
                    toastOpen(<NotAvailable translationsData={translationsData}/>)
                },
                "false": () => {}
            },
            "false": {
                "true": () => {
                },
                "false": () => {
                }
            },

        }
        openToastHandler[`${parseFloat(allowCheckoutValue) === 0}`][`${customerToken === ""}`]()
    }, []);

    useEffect(() => {
        const cartDropDown = {
            "true": (
                <CustomCartDropDown
                    isCheckAllow={isCheckAllow}
                    cart={cartData}
                    items={cartData?.items?.map((item: ICartItemType) => {
                        const showLoader = {"true": "btn-loading", "false": ""};
                        const trueImageUrl: Record<string, string> = {
                            "true": `/storage/medium/${item.product.images[0]?.path}`,
                            "false": `/admin-themes/wt-admin-theme-one/assets/images/placeholder-icon.svg`
                        }
                        return (
                            <div key={item.id} className={cls.dropcart__product}>
                                <div className={`${cls["product-image"]} ${cls["dropcart__product-image"]}`}>
                                    <Link href={`/${item.product.url_key}`} className={cls["product-image__body"]}>
                                        <a>
                                            <Image
                                                src={domainUrl(`${dbName}${trueImageUrl[`${item.product.images.length > 0}`]}`)}
                                                alt={item.name}
                                                height={56}
                                                width={56}
                                                objectFit="contain"
                                                priority
                                            />
                                        </a>
                                    </Link>
                                </div>
                                <div className={cls["dropcart__product-info"]}>
                                    <div className={cls["dropcart__product-name"]}>
                                        <Link href={`/${item.product.url_key}`}>
                                            <a>
                                                {item.name}
                                            </a>
                                        </Link>
                                    </div>
                                    <div className={cls["dropcart__product-meta"]}>
                                        <span className={cls["dropcart__product-quantity"]}>{item.quantity}</span>
                                        {" × "}
                                        <span className={cls["dropcart__product-price"]}>
                            {item.formated_base_price}
                        </span>
                                    </div>
                                </div>
                                <button
                                    onClick={() => dispatch(cartRemoveItem(item.id, cartToken, {token: customerToken}))}
                                    className={`${cls["dropcart__product-remove"]} btn-light btn-sm btn-svg-icon ${showLoader[`${loading === item.id}`]}`}
                                >
                                    <Cross10Svg/>
                                </button>
                            </div>
                        );
                    })}
                    allowCheckoutValue={allowCheckoutValue}
                    selectedRate={selectedRate}
                    translationsData={translationsData}
                />
            ),
            "false": (
                <div className={cls.dropcart}>
                    <div className={cls.dropcart__empty}>
                        {translationsData?.CartEmpty}
                    </div>
                </div>
            )
        }

        setDropDown(cartDropDown[`${!!cartData.items?.length}`])
        //TODO !!! if this dependencies is wrong , change
    }, [cartData.items?.length, loading, locale, selectedRate.code, cartData.grand_total]);



    return (
        <CustomIndicator
            className={cls["cart-icon"]}
            url="/cart"
            dropdown={dropDown}
            value={cartData?.items?.length}
            icon="cartIcon"
            title={translationsData?.Cart}
            iconWrapperClassName=""
        />
    );
}


export default CustomIndicatorCart;