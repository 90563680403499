import React from "react";
import {useToast} from "../../../../../atoms/Toast/useToast";
import {FailSvg} from "../../../../../svg";
import {useSelector} from "react-redux";
import {IState} from "../../../../../types/state";
import cls from "../customHeader.module.scss"
import CustomIndicator from "./CustomIndicator";
import {TranslationsType} from "../../../../../types/homePageTypes";

const CustomFailPanel = ({translationsData}: { translationsData: TranslationsType }) => {
    const toast = useToast();
    const wishlist = useSelector((state: IState) => state.wishlist);

    return (
        <div
            className={`${cls.indicator} ${cls["indicator--trigger--click"]}`}
            onClick={(e) => {
                e.preventDefault();
                toast.open(
                    <span className={`d-flex faild-toast-fms`}>
                        <FailSvg/>
                        {translationsData.ToastSignIn}
                    </span>
                );
            }}
        >
            <CustomIndicator
                url=""
                value={wishlist.length}
                icon="heartIcon"
                title={translationsData?.WishList}
                className=""
                dropdown=""
                iconWrapperClassName=""
            />
        </div>
    )
};

export default CustomFailPanel;