import React, {FC, Fragment, MouseEventHandler, useState} from "react";
import {useRouter} from "next/router";
import cls from "../customHeader.module.scss"
import {domainUrl} from "../../../../../helper";
import Script from "next/script";
import {schemaCategoriesType} from "../../../../../types/header";
import {ICategory} from "../../../../../types/productPageTypes";
import Link from "next/link"
import {ArrowRoundedRight6x9Svg} from "../../../../../svg";
import CustomDepartmentsSubmenu from "./CustomDepartmentsSubmenu";

const schemaCategories: (catChildren: ICategory[], dbName: string, asPath: string) => schemaCategoriesType =
    (catChildren, dbName, asPath) => {
        let positionCounter = 1
        return {
            "@context": `https://schema.org/`,
            "@type": "Menu",
            "url": domainUrl(dbName + asPath),
            "name": "Categories",
            "description": "Categories description",
            "hasMenuSection": catChildren.map((item: ICategory) => ({
                "@type": "MenuSection",
                "name": "Categories menu section",
                "position": positionCounter + 1,
                "hasMenuItem": {
                    "@type": "MenuItem",
                    "name": item.name,
                    "description": item.meta_description,
                },
            }))
        }
    };

interface ICustomDepLinks {
    categories: ICategory[];
    dbName: string
    setOpen: (arg: boolean) => void
}

const CustomDepartmentsLinks: FC<ICustomDepLinks> = ({
                                                         categories: [{children: catChildren = []} = {}] = [],
                                                         dbName,
                                                         setOpen
                                                     }) => {
    const {asPath} = useRouter();
    const [offsetTop, setTop] = useState("");
    const [left, setLeft] = useState("");

    const linksList = catChildren.map((department: ICategory, index: number) => {
        const menuLinkSlugHandler = (item: ICategory) => {
            return {
                "true": `/catalog/${item.slug}`,
                "false": `/${item.code}`,
            }
        }

        const onMouseEnterHandler = (e: {
            target: {
                getBoundingClientRect: () => { top: string; left: string };
                offsetWidth: string
            }
        }) => {
            const re = e.target.getBoundingClientRect();
            setTop(re.top);
            setLeft(re.left + e.target.offsetWidth);
        };

        const hasArrow = {
            "true": <ArrowRoundedRight6x9Svg className={cls["departments__link-arrow"]}/>,
            "false": null
        }
        const hasMenuItems = (elems: ICategory, styles = {}, className = "") => {
            return {
                "true": <div key={index} style={styles} className={cls[className]}>
                    <CustomDepartmentsSubmenu
                        elems={elems}
                        menuLinkSlugHandler={menuLinkSlugHandler}
                        setOpen={setOpen}
                        hasArrow={hasArrow}
                        hasMenuItems={hasMenuItems}
                    />
                </div>,
                "false": null
            }
        }

        const submenu = {
            "true": () => hasMenuItems(
                department,
                {top: offsetTop, left},
                "departments__menu"
            )[`${department?.children?.length > 0}`],
            "false": () => null,
        }

        return (
            <li className={cls.departments__item__li} onClick={() => setOpen(false)} key={index}
                onMouseEnter={onMouseEnterHandler as unknown as MouseEventHandler<HTMLLIElement>}
            >
                <Link href={`/catalog/${department.slug}`}>
                    <a className={cls.departments__item__li_a}>
                        {department.name}
                        {hasArrow[`${department?.children?.length > 0}`]}
                    </a>
                </Link>
                {submenu[`${department?.children?.length > 0}`]()}
            </li>
        );
    });
    const schemaCat = schemaCategories(catChildren, dbName, asPath);

    return (
        <Fragment>
            <Script
                id={schemaCat.name}
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(schemaCat)}}
                defer
            />
            <ul className={cls.departments__links}>{linksList}</ul>
        </Fragment>
    );
}

export default CustomDepartmentsLinks