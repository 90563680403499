import React, {FC, useEffect, useState} from 'react';
import {ArrowRoundedUp13x8Svg} from "../../../../svg";
import cls from "./customFooter.module.scss"

const CustomToTop: FC = () => {
    const [show, setShow] = useState<boolean>(false);
    const showFrom = 300;
    const classNameToggle = {
        "true": "toTop--show",
        "false": ""
    }

    const onClick = () => {
        try {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        } catch {
            window.scrollTo(0, 0);
        }
    };

    useEffect(() => {
        let state = false;
        const onScroll = () => {
            const newState = window.pageYOffset >= showFrom;
            if (state !== newState) {
                setShow(state = newState);
            }
        };

        window.addEventListener('scroll', onScroll, {passive: true});

        return () => window.removeEventListener('scroll', onScroll, {passive: true} as EventListenerOptions);
    }, [setShow]);

    return (
        <div className={`${cls.toTop} ${cls[classNameToggle[`${show}`]]}`}>
            <div className={cls["toTop__body"]}>
                <div className={cls["toTop__end"]}>
                    <button type="button" className={cls["toTop__button"]} onClick={onClick}>
                        <ArrowRoundedUp13x8Svg/>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CustomToTop;
