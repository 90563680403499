import React, {FC} from "react";
import {FailSvg} from "../../../../svg"
import cls from "./customHeader.module.scss"
import {TranslationsType} from "../../../../types/homePageTypes";

const NotAvailable: FC<{ translationsData: TranslationsType }> = ({translationsData}) => (
    <span className="faild-toast-fms">
        <FailSvg/>
        {translationsData.ToastSignIn}
    </span>
)

export default NotAvailable;
