import React, {FC, useEffect, useRef, useState} from "react";
import {VektorMenu} from "../../../../../svg";
import cls from "../customHeader.module.scss"
import CustomDepartmentsLinks from "./CustomDepartmentsLinks"
import {ICategory} from "../../../../../types/productPageTypes";

interface ICustomDepartments {
    categories: Array<ICategory>;
    isTwoKings: boolean;
    dbName: string;
}

const CustomDepartments: FC<ICustomDepartments> = ({
                                                       categories,
                                                       isTwoKings,
                                                       dbName
                                                   }) => {
    const [open, setOpen] = useState<boolean>(false);
    let rootRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handleOutsideClick = (e: any) => {
            if (rootRef.current && !rootRef.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", handleOutsideClick)

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    }, []);

    const handleButtonClick = () => {
        setOpen((prevState) => !prevState);
    };

    const catNotForTwoKings = {
        "true": <></>,
        "false": (
            <button className={cls.departments__button} onClick={handleButtonClick}>
                <VektorMenu/>
                <span className={cls["category-title-fms"]}>
                    {categories?.[0]?.name}
                </span>
            </button>
        )
    }

    const departmentClasses = {
        "true": `${cls.departments} ${cls["departments--opened"]}`,
        "false": cls.departments,
    }

    return (
        <div className={departmentClasses[`${open}`]} ref={rootRef}>
            <div className={cls.departments__body}>
                <div className={cls["departments__links-wrapper"]}>
                    <CustomDepartmentsLinks
                        categories={categories}
                        dbName={dbName}
                        setOpen={setOpen}
                    />
                </div>
            </div>
            {catNotForTwoKings[`${isTwoKings}`]}
        </div>
    )
}

export default CustomDepartments;