import React, {FC, MouseEventHandler, useState} from "react";
import cls from "../customHeader.module.scss"
import {ICategory} from "../../../../../types/productPageTypes";
import Link from "next/link"

interface ICustomDepSubmenu {
    elems: ICategory;
    menuLinkSlugHandler: (arg: ICategory) => Record<string, string>
    setOpen: (arg: boolean) => void
    hasArrow: Record<string, React.JSX.Element | null>
    hasMenuItems: (elems: ICategory, styles: Record<string, string>, className: string) => Record<string, React.JSX.Element | null>
}

const CustomDepartmentsSubmenu: FC<ICustomDepSubmenu> = ({
                                                             elems,
                                                             menuLinkSlugHandler,
                                                             setOpen,
                                                             hasArrow,
                                                             hasMenuItems
                                                         }) => {
    const [offsetTop, setTop] = useState("");
    const [left, setLeft] = useState("");

    const onMouseEnterHandler = (e: {
        target: {
            getBoundingClientRect: () => { top: string; left: string };
            offsetWidth: string
        }
    }) => {
        const re = e.target.getBoundingClientRect();
        setTop(re.top);
        setLeft(re.left + e.target.offsetWidth);
    };

    return (
        <ul className={cls.menu}>
            {elems?.children?.map((item, index) => {
                return <li className={cls.departments__item__li} key={index}
                           onMouseEnter={onMouseEnterHandler as unknown as MouseEventHandler<HTMLLIElement>}
                >
                    <Link href={menuLinkSlugHandler(item)[`${!!item.slug}`]}>
                        <a className={cls.departments__item__li_a}>
                            <div
                                className={`${cls["dropdown-submenu-items"]}`}
                                onClick={() => setOpen(false)}
                            >
                                {item.name}
                            </div>
                            {hasArrow[`${item.children.length > 0}`]}
                        </a>
                    </Link>
                    {hasMenuItems(item, {
                        top: offsetTop,
                        left
                    }, "departments__submenu")[`${item?.children?.length > 0}`]}
                </li>
            })}
        </ul>
    );
}

export default CustomDepartmentsSubmenu