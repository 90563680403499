import {useState} from "react";
import shopApi from "../api/shop";

const useSubscription = () => {
    const [message, setMessage] = useState<string>("");
    const [messageClass, setMessageClass] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const subscribe = (email: string) => {
        if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            setLoading(true);

            shopApi.setSubscription(email)
                .then((data) => {
                    if (data.success) {
                        setMessage(data.success);
                        setMessageClass("text-success");
                    } else {
                        setMessage(data.success);
                        setMessageClass("text-danger");
                    }
                })
                .catch((error) => {
                    console.error(error, "error");
                    setMessage("An error occurred");
                    setMessageClass("text-danger");
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setMessage("Invalid Email Format");
            setMessageClass("text-danger");
        }
    };

    return {message, messageClass, loading, subscribe};
};

export default useSubscription
